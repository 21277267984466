import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
// import App from './App';
// import './App'
import * as serviceWorker from './serviceWorker';
import IndustrialViewer from '@wg/industrial-viewer';
// import BlueprintViewer from '@wg/blueprint-viewer';

Object.assign(document.documentElement.style, { height: '100%' });
Object.assign(document.body.style, { height: '100%', margin: '0', display: 'flex' });
document.body.innerHTML = '<div id="iv"></div><div id="bv"></div>';

const container3d = document.querySelector('#iv');
Object.assign(container3d.style, { height: '100%', width: '50%' });

const container2d = document.querySelector('#bv');
Object.assign(container2d.style, { height: '100%', width: '50%' });

const widget3d = new IndustrialViewer(container3d);
// const widget2d = new BlueprintViewer(container2d);

async function load3d() {
   await widget3d.loadModel('http://b3.webgears.app/inlined/models/demo-building/model.gltf');
}

// async function load2d() {
//    const manifest = await widget2d.loadManifest('http://b3.webgears.app/inlined/models/demo-building/blueprints/manifest.json');
//    await widget2d.loadBlueprint(manifest.pages[2].path); // load 3rd page
// }

load3d();

// ReactDOM.render(
//   <React.StrictMode>
//     {/* <App /> */}
//   </React.StrictMode>,
//   document.getElementById('root')
// );

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
